<template>
    <div class="GeneratedCodes">
        <v-data-table
            class="GeneratedCodes__data-table"
            @update:expanded="rowExpand"
            :headers="headers"
            :items="patientNotes"
            item-key="id"
            outlined
            show-expand
            single-expand
            :loading="getPatientNotesLoading"
        >
            <template v-slot:item.file="{ index, item }">
                <a :href="item.file">Note</a>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-0">
                    <v-card tile flat class="">
                        <v-card-text>
                            <v-row>
                            <v-col>
                                <iframe
                                    :id="item.id+'-file'"
                                    :loading="true"
                                    seamless
                                    height="100%"
                                    width="100%"
                                />
                            </v-col>
                            <v-col>
                                <v-form @submit.prevent>
                                    <JsonEditorVue v-model="patientNotes[patientNotes.map(function(x) {return x.id; }).indexOf(item.id)].generatedCodes"/>
                                        <div class="GeneratedCodes__line"/>
                                    <v-responsive
                                        max-width="344"
                                        class="pt-5"
                                    >
                                        <v-text-field
                                            label="Trigger Workflow ID"
                                            color="primary"
                                            v-model="triggerWorkflow.workflow_id"
                                        ></v-text-field>
                                    </v-responsive>
                                    <v-btn
                                        color="primary"
                                        dense
                                        class="mt-2"
                                        @click="submit(item.id, )"
                                        :loading="approveNoteLoading"
                                    >
                                        Submit
                                    </v-btn>
                                </v-form>
                            </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </td>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import {createNamespacedHelpers} from "vuex";
import JsonEditorVue from 'json-editor-vue'

const { mapGetters: notesGetters, mapActions: notesActions } =
    createNamespacedHelpers('generatedCodes');

export default {
    name: 'Generated Codes',
    computed: {
        ...notesGetters({
            patientNotes: 'PATIENT_NOTES',
            getPatientNotesLoading: 'GET_PATIENT_NOTES_LOADING',
            approveNoteLoading: 'APPROVE_NOTE_LOADING',
        })
    },
  components: {JsonEditorVue},
    data() {
        return {
            headers: [
                { text: 'Note', value: 'file'},
                { text: 'Patient ID', value: 'patient_id'},
                { text: 'Created Date', value: 'createdAt'},
            ],
            triggerWorkflow: {
                workflow_id: undefined
            },
            axios: null,
        }
    },
    methods: {
        ...notesActions(['getPatientNotes', 'approveNote']),
        changeCodeStatus(noteId, codeName, code) {
            const noteIndex = this.patientNotes.map(function(x) {return x.id; }).indexOf(noteId)
            const { checked } = this.patientNotes[noteIndex].generatedCodes[codeName][code]
            this.patientNotes[noteIndex].generatedCodes[codeName][code].status = checked ? 'approve' : 'disapprove'
        },
        submit(noteId) {
            const noteIndex = this.patientNotes.map(function(x) {return x.id; }).indexOf(noteId)
            this.approveNote({ noteId: noteId, codes: this.patientNotes[noteIndex].generatedCodes, triggerWorkflow: this.triggerWorkflow }).then(() => {
                this.patientNotes.splice(noteIndex, 1)
            })
        },
        getS3File(url, noteId) {
            this.axios.get(url, { responseType: 'blob', headers: {Authorization: undefined} }).then((resp) => {
                const iframe = document.getElementById(`${noteId}-file`);
                iframe.setAttribute("src", URL.createObjectURL(resp.data).toString());
                iframe.setAttribute("loading", false);
            });
        },
        rowExpand(items) {
            const itemsToRender = items.filter((item) => !item.fileBlob)
            itemsToRender.map((item) => {
                console.log(item)
                this.getS3File(item.file, item.id)
            })
        }
    },
    mounted() {
        this.axios = window.axios.create({})
        this.getPatientNotes()
    }
};
</script>

<style lang="scss" scoped>
@import './generated-codes';
</style>
